const resourceLanguages = [
  {
    id: "EN",
    name: "English",
    order: 10,
  },
  {
    id: "CN",
    name: "Chinese",
    order: 20,
  },
  {
    id: "TM",
    name: "Tamil",
    order: 30,
  },
  {
    id: "MY",
    name: "Malay",
    order: 40,
  },
  {
    id: "SE",
    name: "Semai",
    order: 50,
  },
];

export const resourceLanguagesAll = [
  {
    id: "All",
    name: "All Languages",
    order: 0,
  },
  ...resourceLanguages,
];

export default resourceLanguages;
