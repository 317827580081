import {
  AspectRatio,
  Badge,
  Flex,
  Heading,
  List,
  ListItem,
  ListProps,
  Text,
  theme,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as React from "react";
import resourceCategories from "../../cms/collections/resourceCategories";
import { mainColors } from "../../components/FilterPanel";
import { ALL, ALL_CATEGORIES } from "./ResourcesSection";

interface ResourcesPanelProps extends ListProps {
  categoryIds: string[];
  selectedCategoryId: string;
  languages: string[];
  selectedLanguage: string;
  data: any;
}

export const ResourcesPanel = (props: ResourcesPanelProps) => {
  const {
    categoryIds,
    selectedCategoryId,
    selectedLanguage,
    data,
    languages,
    ...rest
  } = props;

  const filteredIds = getFilteredCategoryIds(categoryIds, selectedCategoryId);

  const categories = filteredIds.map((id) => {
    const category = resourceCategories.find((c) => c.id === id);
    return category;
  });

  const totalFilteredCount = filteredIds.reduce((total, category) => {
    const resources = data.allMdx.edges.filter((edge) => {
      const c = edge.node.frontmatter.category;
      return c === category;
    });
    const filteredResources = getFilteredResources(resources, selectedLanguage);
    return total + filteredResources.length;
  }, 0);

  const sortByLanguages = (edgeA, edgeB) => {
    const { title: titleA, language: languageA } = edgeA.node.frontmatter;
    const { title: titleB, language: languageB } = edgeB.node.frontmatter;
    const languageIndexA = languages.findIndex((l) => l === languageA);
    const languageIndexB = languages.findIndex((l) => l === languageB);
    if (titleA > titleB) {
      return 1;
    } else if (titleA < titleB) {
      return -1;
    } else {
      return languageIndexA - languageIndexB;
    }
  };

  return (
    <List flexDirection="column" {...rest}>
      {categories
        .sort((a, b) => a.order - b.order)
        .map((category, i) => {
          if (!category) {
            return null;
          }
          const resources = data.allMdx.edges.filter((edge) => {
            const c = edge.node.frontmatter.category;
            return c === category.id;
          });
          const filteredResources = getFilteredResources(
            resources,
            selectedLanguage
          );
          if (filteredResources.length === 0) {
            return null;
          }
          const sortedResources = filteredResources.sort(sortByLanguages);
          return (
            <ListItem
              pb="8"
              flexDirection="column"
              key={`${i} - ${category.id}`}
            >
              <Heading
                color="delta"
                mx="8"
                fontSize="2xl"
                fontWeight="semibold"
                as="h2"
              >
                {category.exampleDescription}
              </Heading>
              <List
                display="grid"
                gridTemplateColumns={[
                  "repeat(2, 1fr)",
                  null,
                  "repeat(4, 1fr)",
                  "repeat(6, 1fr)",
                ]}
                gridAutoRows="auto"
                gridColumnGap={[2, null, null, 8]}
                gridRowGap={[2, null, null, 4]}
                mt="4"
                mx={[1, null, null, 8]}
                as="ul"
              >
                {sortedResources.map((edge) => {
                  const { node } = edge;
                  const image = getImage(node.frontmatter.image);
                  const languageIndex = languages.findIndex(
                    (l) => l === node.frontmatter.language
                  );
                  return (
                    <ListItem key={node.id} minWidth="0">
                      <Link to={`/resources/${node.slug}`}>
                        <ImageContainer minWidth="100%" ratio={4 / 3}>
                          <GatsbyImage
                            image={image}
                            alt={node.frontmatter.title}
                          />
                        </ImageContainer>
                      </Link>
                      <Flex alignItems="center" my="2">
                        <Badge
                          display="flex"
                          height="100%"
                          variant="outline"
                          alignItems="center"
                          borderRadius="sm"
                          mr="2"
                          colorScheme={
                            mainColors[languageIndex % mainColors.length]
                          }
                        >
                          {node.frontmatter.language}
                        </Badge>
                        <Text
                          fontSize="sm"
                          textShadow="lg"
                          color="blackAlpha.700"
                          textAlign="left"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {node.frontmatter.title}
                        </Text>
                      </Flex>
                    </ListItem>
                  );
                })}
              </List>
            </ListItem>
          );
        })}
      {totalFilteredCount === 0 && (
        <Heading
          as="h3"
          color="delta"
          fontSize="xl"
          fontWeight="semibold"
          margin="8"
          textAlign={["center", null, null, "left"]}
        >
          No resources found
        </Heading>
      )}
    </List>
  );
};

const ImageContainer = styled(AspectRatio)`
  .gatsby-image-wrapper {
    box-shadow: ${theme.shadows.md};
    border: 1px solid ${theme.colors.blackAlpha[200]};
    border-radius: ${theme.radii["md"]};
  }
  img {
    border-radius: ${theme.radii["md"]};
  }
`;

const getFilteredCategoryIds = (
  categories: string[],
  selectedCategory: string
) => {
  return selectedCategory === ALL_CATEGORIES.toLowerCase()
    ? categories
    : categories.filter(
        (category) => category.toLowerCase() === selectedCategory
      );
};

const getFilteredResources = (resources: any, selectedLanguage: string) => {
  return selectedLanguage === ALL
    ? resources
    : resources.filter(
        (edge) => edge.node.frontmatter.language === selectedLanguage
      );
};
