import { graphql } from "gatsby";
import React from "react";
import { Header } from "../../components/Header";
import { Layout } from "../../components/Layout";
import { Main } from "../../components/Main";
import { HeaderBar } from "../../components/HeaderBar";
import { ResourcesSection } from "../../features/home/ResourcesSection";
import { Button, IconButton, Link } from "@chakra-ui/react";
import { FaCloudUploadAlt, FaUpload } from "react-icons/fa";

const IndexPage = ({ data }) => {
  const category = getParam("category");
  return (
    <Layout>
      <Header flexDirection="column">
        <HeaderBar
          rightContent={
            <IconButton
              aria-label="Upload Resource"
              colorScheme="red"
              icon={<FaCloudUploadAlt />}
              as={Link}
              href="https://forms.gle/gFcnT2Ftowtq1oEs8"
              isExternal
            />
          }
        />
      </Header>
      <Main pb="16" alignItems="center">
        <ResourcesSection
          data={data}
          selectedCategory={category}
          minWidth="100%"
        />
      </Main>
    </Layout>
  );
};

const getParam = (param: string) => {
  if (typeof window === "undefined") {
    return null;
  }
  const url = new URL(window.location.href);
  const value = url.searchParams.get(param);
  return value?.toLowerCase();
};

export const query = graphql`
  query resources {
    allMdx(filter: { fileAbsolutePath: { regex: "/content/resources/" } }) {
      edges {
        node {
          id
          frontmatter {
            title
            category
            language
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          body
          slug
        }
      }
    }
  }
`;

export default IndexPage;
