import { Flex, FlexProps } from "@chakra-ui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { resourceLanguagesAll } from "../../cms/collections/resourceLanguages";
import { FilterPanel } from "../../components/FilterPanel";
import { ResourcesPanel } from "./ResourcesPanel";

export const ALL_CATEGORIES = "ALL";
export const ALL = "All";

export interface ResourcesSectionProps extends FlexProps {
  selectedCategory: string;
  data: any;
}

export const ResourcesSection = (props: ResourcesSectionProps) => {
  const { data, selectedCategory, ...rest } = props;
  const [selectedLanguage, setSelectedLanguage] = useState(ALL);
  const categoryExistMap = data.allMdx.edges.reduce((allCategories, edge) => {
    const { category } = edge.node.frontmatter;
    allCategories[category] = 1;
    return allCategories;
  }, {});
  const categoryIds = Object.keys(categoryExistMap);
  const categoryIdsAll = [ALL_CATEGORIES, ...categoryIds];

  const languageMap = data.allMdx.edges.reduce((allLanguages, edge) => {
    const { language } = edge.node.frontmatter;
    allLanguages[language] = 1;
    return allLanguages;
  }, {});
  const languages = Object.keys(languageMap);
  const languagesAll = [ALL, ...languages];
  const sortedLanguages = languagesAll.sort((a, b) => {
    const languageA = resourceLanguagesAll.find((l) => l.id === a);
    const languageB = resourceLanguagesAll.find((l) => l.id === b);
    return languageA.order - languageB.order;
  });

  useEffect(() => {
    setSelectedLanguage(ALL);
  }, [selectedCategory]);

  return (
    <Flex flexDirection="column" as="section" maxWidth="100%" {...rest}>
      <Flex
        flexDirection={["column-reverse", null, null, "row"]}
        width="100%"
        maxWidth="100%"
        justifyContent="space-between"
      >
        <FilterPanel
          items={sortedLanguages}
          selectedItem={selectedLanguage}
          onBadgeClick={setSelectedLanguage}
          flex={[null, null, null, 1]}
          mt={["2", null, null, "4"]}
          ml={[null, null, null, "6"]}
        />
      </Flex>
      <ResourcesPanel
        languages={sortedLanguages}
        selectedCategoryId={selectedCategory}
        selectedLanguage={selectedLanguage}
        categoryIds={categoryIdsAll}
        data={data}
        pt="8"
      />
    </Flex>
  );
};
