import { Badge, Flex, FlexProps, theme } from "@chakra-ui/react";
import * as React from "react";

interface FilterPanelProps extends FlexProps {
  items: string[];
  selectedItem: string;
  onBadgeClick(filter: string): void;
}
export const FilterPanel = (props: FilterPanelProps) => {
  const { items, selectedItem, onBadgeClick, ...rest } = props;
  return (
    <Flex overflowX="auto" maxWidth="100%" {...rest}>
      {items.map((item, i) => {
        return (
          <Badge
            key={`${i} - ${item}`}
            borderRadius="md"
            paddingY="1"
            paddingX="3"
            marginY="2"
            marginX="2"
            colorScheme={mainColors[i % mainColors.length]}
            variant={selectedItem === item ? "outline" : "solid"}
            onClick={() => onBadgeClick(item)}
            cursor="pointer"
          >
            {item}
          </Badge>
        );
      })}
    </Flex>
  );
};

const colors = Object.keys(theme.colors);
export const mainColors = colors.slice(6, 15);
